import * as React from "react";

const AccentColorIndicator = React.memo(
  ({ width = 16, height = 16, color }) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'>
      <g clipPath='url(#clip0_6346_25832)'>
        <path d='M0 0V16H8V0H0Z' fill={color} />
      </g>
      <rect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke={color} />
      <defs>
        <clipPath id='clip0_6346_25832'>
          <rect width='16' height='16' rx='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
);

export default AccentColorIndicator;
