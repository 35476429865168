import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/InputText";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import {
  H4,
  AvenirBlackH4,
  AvenirBlackH3,
  DotText,
} from "../../../../utils/text";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import ChangeImage from "../create/ChangeImage";
import { ConvertPrice, validateAlphaNumeric } from "../../../../utils/util";
import { fetchImageUrl, putImageUrl } from "../../../../redux/actions";
import CustomTaxMode from "../create/customTaxMode";
import Advanced from "./advanced";

const Basic = ({
  basicInfo = {},
  setBasicInfo,
  setBasicInfoTax,
  ordertypes,
  onEditAttr,
  pizzaConfig,
  taxProfileIdErr,
  advanced,
  setAdvanced,
}) => {
  const dispatch = useDispatch();
  const onChangeFields = (field, value) => {
    let data = value;
    if (field === "name") {
      data = validateAlphaNumeric(value);
    }
    setBasicInfo({ ...basicInfo, [field]: data });
  };
  const onOrderTypeSelect = (val, id) => {
    // console.log(val, id)
    let selected = [...basicInfo.ordertypes];
    const index = selected.findIndex((e) => e === id);
    if (index === -1) {
      selected.push(id);
    } else {
      selected = [...selected.slice(0, index), ...selected.slice(index + 1)];
    }
    setBasicInfo({ ...basicInfo, ordertypes: selected });
  };
  const onImageChange = (image, file) => {
    if (file) {
      dispatch(
        fetchImageUrl(
          {
            file_name: image,
            content_type: file.type,
            image_loc: "menu",
          },
          (response) => {
            completeLogo(response, file, 1);
          }
        )
      );
    } else {
      onChangeFields("image", null);
    }
  };
  const completeLogo = (response, file) => {
    const obj = {};
    const url = response.upload_url.split("?")[0];
    obj.ContentType = file.type;
    obj.Body = file;
    dispatch(
      putImageUrl(
        response.upload_url,
        obj,
        () => {
          onChangeFields("image", url);
        },
        response.storage === "azure"
      )
    );
  };
  const handleOnBlurChange = (data) => {
    if (basicInfo.price !== "") {
      setBasicInfo({ ...basicInfo, price: ConvertPrice(data) });
    }
  };
  return (
    <Grid container>
      <Grid
        container
        direction='row'
        style={{ marginTop: 30, padding: "0px 24px" }}>
        <Grid item xs={8}>
          <InputText
            onChange={(e) => onChangeFields("name", e.target.value)}
            value={basicInfo.name}
            maxLength={64}
            placeholder='Item name'
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <InputText
            onChange={(e) =>
              onChangeFields(
                "price",
                e.target.value.replace("$", "").replace(/[^0-9.?]/g, "") || ""
              )
            }
            value={`${basicInfo.price === "" ? "" : `$${basicInfo.price}`}`}
            placeholder='Price'
            maxLength={8}
            inputPlaceholder='$0.00'
            onBlur={() => handleOnBlurChange(basicInfo.price)}
            // inputProps={{ step: 0.01, min: 0.00 }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        alignItems='center'
        style={{ marginTop: 20, padding: "0px 24px" }}>
        <Grid item xs={6}>
          <H4 label='Active item' style={{ marginRight: 60 }} />
        </Grid>
        <Grid item xs={6}>
          <CustomCheckbox
            checked={basicInfo.isActive}
            onChange={(e) => onChangeFields("isActive", e.target.checked)}
          />
        </Grid>
      </Grid>
      {ordertypes.map((otype) => (
        <Grid
          container
          direction='row'
          alignItems='center'
          style={{ padding: "0px 50px" }}>
          <Grid item xs={6}>
            <DotText label={otype.name} style={{ marginRight: 60 }} />
          </Grid>
          <Grid item xs={6}>
            <CustomCheckbox
              checked={basicInfo.ordertypes.indexOf(otype.otype_id) !== -1}
              onChange={(e) =>
                onOrderTypeSelect(e.target.checked, otype.otype_id)
              }
            />
          </Grid>
        </Grid>
      ))}
      <Grid
        container
        direction='row'
        style={{ marginTop: 20, padding: 20, backgroundColor: "#F3FAFD" }}>
        <Grid item xs={6}>
          <InputText
            onChange={(e) => onChangeFields("desc", e.target.value)}
            value={basicInfo.desc}
            placeholder='Description'
            height={150}
            maxLength={256}
            multiline
            inputProps={{ step: 0.01, min: 0.0 }}
            rows={6}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 10 }}>
          <AvenirBlackH4 label='Image' style={{ marginLeft: 5 }} />
          <ChangeImage image={basicInfo.image} onChange={onImageChange} />
        </Grid>
      </Grid>
      <Advanced
        advanced={advanced}
        setAdvanced={setAdvanced}
        ordertypes={ordertypes}
      />

      <Grid container style={{ marginTop: 20, padding: 20 }}>
        {pizzaConfig.map((config) => (
          <Attribute
            item={config}
            onAdd={onEditAttr}
            key={config.byo_type_ingredient_id}
          />
        ))}
      </Grid>
      <Grid
        container
        alignItems='center'
        style={{
          marginTop: 20,
          padding: 20,
        }}>
        <CustomTaxMode
          info={basicInfo}
          setInfo={setBasicInfoTax}
          taxProfileIdErr={taxProfileIdErr}
        />
      </Grid>
    </Grid>
  );
};
const Attribute = ({ item, onAdd }) => (
  <Grid container>
    <Grid
      container
      direction='row'
      style={{
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: "1px solid #EEEEEE",
      }}>
      <Grid item xs={8}>
        <AvenirBlackH3 label={item.byo_type_ingredient_name} />
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid
          container
          direction='row'
          justify='flex-end'
          style={{ width: 55, cursor: "pointer" }}
          onClick={() => onAdd(item)}>
          <CirclePlus color='#004C60' />
          <AvenirBlackH3
            label='Add'
            style={{ marginLeft: 5, color: "#004C60" }}
          />
        </Grid>
      </Grid>
    </Grid>
    {item.byo_ingredients.length > 0 && (
      <Grid container style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid item xs={12}>
          <H4 label='Name' style={{ color: "#707272" }} />
        </Grid>
        <Grid container>
          {item.byo_ingredients.map((bi, index) => {
            let name = bi.byo_item_ingredient_name;
            if (bi.price) {
              name += `  (+$${ConvertPrice(bi.price)})`;
            }
            return (
              <Grid item xs={12} key={index} style={{ marginTop: 16 }}>
                <H4 label={name} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    )}
  </Grid>
);

export default Basic;
