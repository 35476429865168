import * as React from "react";

const FullColorIndicator = React.memo(({ width = 16, height = 16, color }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect width={width} height={height} rx='8' fill={color} />
  </svg>
));

export default FullColorIndicator;
