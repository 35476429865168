import React, { useState } from "react";
import { Grid, Button, InputAdornment, Box } from "@material-ui/core";
import CircleClose from "../../../../components/SVG/CircleClose";
import RightDrawer from "../../../../components/RightDrawer";
import BackIcon from "../../../../components/SVG/Back";
import { ConvertPrice, validateAlphaNumeric } from "../../../../utils/util";
import { AvenirBlackH4, H6, AvenirBlackH3 } from "../../../../utils/text";
import InputText from "../../../../components/InputText";
import InputNumber from "../../../../components/InputNumber";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import DeleteIcon from "../../../../components/SVG/Delete";

const VariablePricing = ({
  show,
  onClose,
  otypes,
  servings,
  onServingChange,
}) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [servingData, setServingData] = useState(
    servings.map((item) => ({
      ...item,
      pricings: item.pricings.map((priceValue) => ({
        ...priceValue,
        price: parseFloat(priceValue.price || 0).toFixed(2),
      })),
    }))
  );
  const [errorCheck, setErrorCheck] = useState(false);

  const onServingNameChange = (value, index) => {
    const s = [...servingData];
    s[index].name = validateAlphaNumeric(value);
    setErrorCheck(value === "");

    setServingData(s);
  };
  const onServingPriceChange = (value, index, indx) => {
    const s = [...servingData];
    const pricing = s[index].pricings;
    pricing[indx].price = value;
    s[index].pricings = pricing;
    setServingData(s);
  };
  const addNew = () => {
    const obj = {
      name,
    };
    if (name) {
      const pricings = [];
      otypes.map((o) => {
        pricings.push({ ...o, price: price || 0 });
      });
      obj.pricings = pricings;
      setServingData([...servingData, obj]);
      setName("");
      setPrice("");
    } else {
      setErrorCheck(true);
    }
  };
  const onSave = () => {
    if (errorCheck === false) {
      onServingChange(servingData);
    } else {
      setErrorCheck(true);
    }
  };
  const onDelete = (index) => {
    setServingData([
      ...servingData.slice(0, index),
      ...servingData.slice(index + 1),
    ]);
  };
  const handleOnBlurChange = (value) => {
    setPrice(ConvertPrice(value));
  };
  const handleName = (data) => {
    setName(validateAlphaNumeric(data));
  };
  return (
    <RightDrawer open={show} padding={1}>
      <Grid
        container
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-start",
          height: "100%",
          minWidth: 592,
          maxWidth: 592,
          padding: "10px 24px",
        }}>
        <Grid
          container
          // eslint-disable-next-line no-undef
          style={{ height: window.innerHeight - 100, overflow: "auto" }}>
          <Grid container style={{ height: 60 }}>
            <Grid
              container
              data-qaid='assignkitchen_close_grid'
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <CircleClose
                data-qaid='assignkitchen_closeicon'
                size={20}
                onClick={onClose}
                style={{ marginTop: 20 }}
              />
            </Grid>
            <Grid
              container
              data-qaid='assignkitchen_settings_grid'
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",

                marginBottom: 10,
              }}>
              <BackIcon
                data-qaid='categories_editBack_icon'
                onClick={onClose}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: 20 }}>
            <H6 label='Variable pricing (size & order type)' />
          </Grid>
          <Grid
            container
            style={{
              marginTop: 40,
              borderBottom: "1px solid #EEE",
              height: 40,
            }}>
            <AvenirBlackH3 label='Add size' />
          </Grid>
          <Grid
            container
            style={{ marginTop: 40, justifyContent: "space-between" }}>
            <Grid item xs={7}>
              <InputText
                value={name}
                maxLength={64}
                onChange={(e) => handleName(e.target.value)}
                placeholder='Item size'
                error={errorCheck && "Item size is required"}
              />
            </Grid>
            <Grid item xs={4}>
              <AvenirBlackH4 label='Price' />
              <InputText
                value={price}
                maxLength={8}
                startAdornment={
                  <InputAdornment position='start'>+$</InputAdornment>
                }
                isPlaceHolder={false}
                placeholder='eg +$1.23'
                onBlur={() => {
                  handleOnBlurChange(price);
                }}
                onChange={(e) =>
                  setPrice(e.target.value.replace(/[^0-9.]/g, ""))
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify='flex-start'
            alignItems='center'
            style={{
              marginTop: 20,
            }}>
            <Box
              onClick={addNew}
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
              }}>
              <CirclePlus color='#004C60' width={21} height={21} />
              <AvenirBlackH4
                label='Add'
                style={{ color: "#004c60", marginLeft: 10 }}
              />
            </Box>
          </Grid>
          <Grid
            container
            style={{
              marginTop: 60,
              borderBottom: "1px solid #EEE",
              height: 40,
            }}>
            <AvenirBlackH3 label='Configure order types' />
          </Grid>
          <Grid
            container
            style={{ width: 500, overflow: "auto", paddingBottom: 100 }}>
            {servingData.map((serving, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  marginTop: 20,
                  alignItems: "center",
                  height: "95px",
                }}>
                <span style={{ width: 120, height: 79 }}>
                  <AvenirBlackH4 label='Size name' />
                  <InputText
                    value={serving.name}
                    maxLength={64}
                    error={serving.name.length === 0 && "size is required"}
                    onChange={(e) => onServingNameChange(e.target.value, index)}
                  />
                </span>
                {otypes.map((ot, indx) => {
                  const price = serving.pricings.find(
                    (p) => p.otype_id === ot.otype_id
                  );
                  return (
                    <span
                      key={indx}
                      style={{
                        marginLeft: 10,
                        width: 120,
                        display: "block",
                      }}>
                      <AvenirBlackH4 label={`price(${ot.otype_name})`} />
                      <InputNumber
                        startAdornment={
                          <InputAdornment position='start'>$</InputAdornment>
                        }
                        inputProps={{ step: 0.01, min: 0 }}
                        onBlur={() => {
                          onServingPriceChange(
                            ConvertPrice(price?.price),
                            index,
                            indx
                          );
                        }}
                        value={price?.price}
                        maxLength={8}
                        onChange={(e) =>
                          onServingPriceChange(
                            e.target.value.replace(/[^0-9.]/g, ""),
                            index,
                            indx
                          )
                        }
                      />
                    </span>
                  );
                })}
                <span
                  style={{
                    marginLeft: 10,
                    width: 40,
                    marginTop: 20,
                    display: "inline-block",
                  }}>
                  <DeleteIcon onClick={() => onDelete(index)} />
                </span>
              </div>
            ))}
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            backgroundColor: "white",
            position: "absolute",
            height: 60,
            borderTop: "1px solid #EEE",
            justifyContent: "flex-end",
            bottom: 0,
            left: 0,
            alignItems: "center",
          }}>
          <Grid item xs={12} md={6} style={{ marginRight: 20 }}>
            <Grid container justify='flex-end'>
              <Button
                style={{ border: "2px solid #707272", marginRight: 10 }}
                onClick={onClose}>
                <AvenirBlackH4 label='Cancel' />
              </Button>
              <Button
                variant='contained'
                style={{ backgroundColor: "#FFB600" }}
                onClick={onSave}>
                <AvenirBlackH4 label='Save' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default VariablePricing;
