import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import MyContext from "../../../Provider/MyContext";
import { AvenirBlackH4, H6, H4, AvenirBlackH3, H5 } from "../../../utils/text";
import {
  AccentColorIndicator,
  FullColorIndicator,
  UploadIcon,
  Edit as EditOutlinedIcon,
  NoImage,
} from "../../../components/SVG";
import styles from "../../../styles/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 200,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  cardContainer: {
    backgroundColor: "white",
    borderWidth: "1px",
    border: "1px solid #EEEEEE",
    height: 190,
    display: "flex",
    justifyContent: "center",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover": { backgroundColor: "rgb(200, 230, 241)" },
  },
  buttonClass: {
    height: 40,
    borderRadius: 5,
    "&:hover": { backgroundColor: "rgb(200, 230, 241)" },
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

const ItemCard = ({ item, enableEditDrawer, uploadImageCallBack }) => {
  const style = styles();
  const [imageToggle, setImageToggle] = useState(false);
  const Context = useContext(MyContext);
  const classes = useStyles();

  const [img, setImg] = useState("");
  useEffect(() => {
    if (item.img_url) {
      setImg(item.img_url);
    } else {
      setImg("");
    }
  }, [item]);
  const saveImage = () => {
    setImageToggle(false);
    // eslint-disable-next-line no-undef
    const fileChooser = document.getElementById(`image-${item.item_id}`);
    const { files } = fileChooser;
    if (files.length > 0) {
      const file = files[0];
      uploadImageCallBack(file, item.item_id);
    }
  };
  const cancelImage = () => {
    setImageToggle(false);
    if (item.img_url) {
      setImg(item.img_url);
    } else {
      setImg("");
    }
  };
  const getDateFormat = (timestamp) => {
    const val = moment.unix(timestamp).format("MMMM DD, YYYY");
    return val;
  };
  return (
    <Grid
      className={classes.cardContainer}
      onClick={() => enableEditDrawer(item, true)}>
      <Grid style={{ flexDirection: "row", display: "flex", width: "100%" }}>
        <Grid
          style={{
            width: "55%",
            display: "flex",
            flexDirection: "column",
            padding: 5,
          }}>
          <Grid
            container
            style={{
              minHeight: "40%",
              display: "flex",
              alignItems: "center",
              paddingLeft: 15,
            }}>
            {item.bg_colour !== "#FFFFFF" &&
              (item.button_style === 1 ? (
                <FullColorIndicator color={item.bg_colour} />
              ) : (
                <AccentColorIndicator color={item.bg_colour} />
              ))}

            <AvenirBlackH3
              style={{
                width: 160,
                color: "#051d33",
                fontSize: 16,
                fontFamily: "AvenirLT-Medium",
                wordWrap: "break-word",
                marginLeft: 5,
              }}
              label={item.name}
            />
          </Grid>
          <Grid
            container
            style={{
              minHeight: "10%",
              display: "flex",
              alignItems: "center",
              marginLeft: 15,
            }}>
            <H4
              label={
                item.available
                  ? Context.langData.active
                  : Context.langData.inactive
              }
            />
          </Grid>
          <Grid
            container
            style={{
              minHeight: "10%",
              display: "flex",
              alignItems: "center",
              marginLeft: 15,
            }}>
            <H5
              label={`${Context.langData.lastModified} ${getDateFormat(
                item.last_updated_on
              )}`}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 10,
              flexDirection: "row",
            }}>
            <Button
              className={classes.buttonClass}
              data-qaid='itemCard_button'
              variant='text'
              id='cancel'
              onClick={() => enableEditDrawer(item, true)}>
              <EditOutlinedIcon
                data-qaid='itemCard_edit_icon'
                width={23}
                height={23}
                color='#007191'
                style={{ marginRight: 10 }}
              />
              <AvenirBlackH4
                data-qaid='itemCard_edit'
                style={{ color: "#007191" }}
                label={Context.langData.edit}
              />
            </Button>
          </Grid>
        </Grid>
        <Grid
          style={{
            width: "45%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            flexWrap: "wrap",
            marginLeft: "5%",
          }}>
          {img ? (
            <Image img={img} id={item.item_id} />
          ) : (
            <NoImage height='100%' width='125%' />
          )}
        </Grid>
      </Grid>
      <Dialog open={imageToggle} TransitionComponent={Transition}>
        <DialogTitle id='alert-dialog-slide-title'>
          <H6 label={`${Context.langData.edit_image}:${item.name}`} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "white",
              }}>
              <Grid
                style={{
                  width: "60%",
                  flex: 1,
                  display: "flex",
                  border: `1px solid`,
                  borderColor: "#C3C3C3",
                  justifyContent: "center",
                }}>
                <Box
                  component='img'
                  src={img}
                  style={{
                    height: "100%",
                    width: "50%",
                    flex: 1,
                    padding: 5,
                  }}
                />
              </Grid>
              <Grid
                style={{
                  width: "40%",
                  border: `1px solid`,
                  borderColor: "#C3C3C3",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  padding: 20,
                  marginLeft: 5,
                }}>
                <H4 label={Context.langData.image_prop} />
                <H4 label={Context.langData.image_type} />
                <H4 label={Context.langData.image_dimensions} />
                <H4 label={`${Context.langData.size} : <100KB`} />
              </Grid>
            </Grid>
            <Grid style={{ marginTop: 20 }}>
              <input
                accept='image/png, image/jpg, image/jpeg'
                style={{ display: "none" }}
                id={`image-${item.item_id}`}
                multiple
                type='file'
                onChange={() => {
                  // eslint-disable-next-line no-undef
                  const fileChooser = document.getElementById(
                    `image-${item.item_id}`
                  );
                  const { files } = fileChooser;
                  if (files.length > 0) {
                    const value = URL.createObjectURL(files[0]);
                    setImg(value);
                  }
                }}
              />
              <label htmlFor={`image-${item.item_id}`}>
                <u>
                  <Typography variant='H5'>
                    {Context.langData.upload_image}{" "}
                  </Typography>
                </u>
              </label>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Grid
            data-qaid='create_category_cancel_btn'
            item
            xs={12}
            lg={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 32,
              marginBottom: 32,
            }}>
            <Button
              className={style.buttonClass}
              data-qaid='create_category_cancel_button'
              variant='contained'
              style={{ backgroundColor: "#00B6C9", marginRight: 16 }}>
              <UploadIcon style={{ marginRight: 10 }} />
              <Box component='label' htmlFor={`image-${item.item_id}`}>
                <AvenirBlackH4
                  data-qaid='create_category_cancel_text'
                  label={Context.langData.upload_image}
                  style={{ textDecoration: "underline" }}
                />
              </Box>
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid='create_category_cancel_button'
              onClick={() => cancelImage()}
              variant='contained'
              style={{ backgroundColor: "#00B6C9", marginRight: 16 }}>
              <AvenirBlackH4
                data-qaid='create_category_cancel_text'
                label={Context.langData.cancel}
              />
            </Button>
            <Button
              className={style.buttonClass}
              data-qaid='create_category_save_btn'
              variant='contained'
              style={{ backgroundColor: "#FFB600", marginRight: 32 }}
              onClick={() => saveImage()}>
              <AvenirBlackH4
                data-qaid='create_category_save_text'
                label={Context.langData.save}
              />
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const Image = ({ img, id }) => {
  const [image, setImage] = useState("");
  useEffect(() => {
    setImage(img);
  }, [img, id]);
  return (
    <span
      id={id}
      style={{
        backgroundImage: `url("${image}")`,
        height: "100%",
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
      }}
    />
  );
};
export default ItemCard;
