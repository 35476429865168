import * as React from "react";

const ColorCheck = (color = "#707272") => (
  <svg
    width='10'
    height='10'
    viewBox='0 0 44 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='0.5'
      y='0.5'
      width='43'
      height='43'
      rx='4.5'
      fill='white'
      stroke={color}
    />
  </svg>
);

export default ColorCheck;
