import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import _ from "lodash";
import {
  fetchModifierItem,
  createAddonCategories,
  fetchAddonCategories,
  editAddonCategories,
  deleteAddonCategories,
} from "../../../redux/actions";
import MyContext from "../../../Provider/MyContext";
import styles from "../../../styles/index";
import { AvenirBlackH4 } from "../../../utils/text";
import { PluseIcon as CirclePluse } from "../../../components/SVG";
import { DataTable, SnackBar, NoData } from "../../../components";
import { useComponentStates, useDrawers } from "../../../custom-hooks";
import ModifierGroupCreateEdit from "./ModifierGroupCreateEdit";

const ModifiersGroupList = () => {
  const style = styles();
  const Context = useContext(MyContext);
  const Categories = useSelector((state) => state.menu.addonCategories);
  const dispatch = useDispatch();
  const modifierItem = useSelector((state) => state.menu.modifierItem);
  const { drawers, toggleDrawer } = useDrawers({
    openCreateCategories: false,
    openEditCategories: false,
  });

  const { componentStates, updateComponentStates } = useComponentStates({
    row: {},
    snackBar: null,
    pagenumber: 0,
    selectedTabData: [],
    columns: [
      {
        field: "addon_cat_name",
        headerName: Context.langData.modifier_group_name,
        size: "50%",
      },
      {
        field: "modifiers",
        headerName: Context.langData.modifier,
        size: "50%",
      },
    ],
  });
  useEffect(() => {
    dispatch(fetchAddonCategories());
  }, []);
  useEffect(() => {
    Categories.map((item) => {
      item.modifiers = item.addon_category_items
        .map((adoonItem) => adoonItem.addon_item_name)
        .join(",");
    });
    updateComponentStates("selectedTabData", Categories);
  }, [Categories]);
  function CommonCallback() {
    toggleDrawer("all");
    dispatch(fetchAddonCategories());
  }

  function CreateCategoriesCallback(isSuccess) {
    CommonCallback();
    if (isSuccess) {
      updateComponentStates("snackBar", {
        message: Context.langData.modifier_group_create_succ,
        severity: "success",
      });
    } else {
      updateComponentStates("snackBar", {
        message: Context.langData.modifier_group_create_failed,
        severity: "error",
      });
    }
  }
  function editCategoriesCallback(isSuccess, msg) {
    CommonCallback();
    if (isSuccess) {
      updateComponentStates("snackBar", {
        message: Context.langData.modifier_group_edit_succ,
        severity: "success",
      });
    } else {
      updateComponentStates("snackBar", {
        message: msg.message,
        severity: "error",
      });
    }
  }
  function deleteCategoriesCallback(isSuccess, data) {
    CommonCallback();
    if (isSuccess) {
      updateComponentStates("snackBar", {
        message: Context.langData.modifier_group_delete_succ,
        severity: "success",
      });
    } else {
      updateComponentStates("snackBar", {
        message: data.status,
        severity: "error",
      });
    }
  }

  const handleCreateModifier = (payload) => {
    dispatch(createAddonCategories(payload, CreateCategoriesCallback));
  };
  const handleDeleteModifier = (payload) => {
    dispatch(
      deleteAddonCategories([payload.addon_cat_id], deleteCategoriesCallback)
    );
  };
  const handleEditModifier = (payload) => {
    dispatch(editAddonCategories(payload, editCategoriesCallback));
  };

  const onPageChange = (value) => {
    updateComponentStates("pagenumber", value);
  };
  const sortingOnClick = (name, desc, index) => {
    const columnData = [...componentStates.columns];
    columnData[index] = { ...columnData[index], sort: !desc };
    updateComponentStates("columns", columnData);
    const sortAddons = _.orderBy(
      componentStates.selectedTabData,
      [name],
      [desc ? "desc" : "asc"]
    );
    updateComponentStates("selectedTabData", sortAddons);
  };
  const onClickAction = (rowValue) => {
    updateComponentStates("row", rowValue);
    dispatch(fetchModifierItem({ addon_cat_id: rowValue.addon_cat_id }));
    toggleDrawer("openEditCategories", true);
  };
  return (
    <Grid
      data-qaid='categories_main_grid'
      container
      justifyContent='flex-start'
      spacing={3}>
      {componentStates.selectedTabData.length > 0 ? (
        <>
          <Grid
            data-qaid='categories_grid'
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 20,
              marginBottom: 10,
              marginTop: 32,
              marginLeft: 32,
            }}>
            <Grid
              data-qaid='categories_grid'
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            />
            <Grid
              data-qaid='categories_grid'
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='categories_creategrid'
                style={{
                  backgroundColor: "#FFB600",
                  color: "white",
                  marginRight: 10,
                }}
                variant='contained'
                id='cancel'
                onClick={() => toggleDrawer("openCreateCategories", true)}>
                <CirclePluse data-qaid='categories_createpluse' />
                <AvenirBlackH4
                  data-qaid='categories_createlabel'
                  label={Context.langData.create_modifier_group}
                  style={{ paddingLeft: 5 }}
                />
              </Button>
            </Grid>
          </Grid>
          <Grid
            data-qaid='categories_body_grid'
            item
            xs={12}
            style={{
              marginLeft: 32,
              marginRight: 32,
              marginBottom: 30,
              width: "100%",
            }}>
            <DataTable
              data-qaid='categories_simpleTable'
              columns={componentStates.columns}
              rows={componentStates.selectedTabData}
              sortingOnClick={sortingOnClick}
              onPageChange={onPageChange}
              // onRowClick={onRowClick}
              onClickAction={onClickAction}
            />
          </Grid>
        </>
      ) : (
        <NoData
          page='addon category'
          callback={() => toggleDrawer("openCreateCategories", true)}
        />
      )}
      {componentStates.snackBar && (
        <SnackBar
          data-qaid='addon_categories_snackbar'
          open
          setOpen={() => updateComponentStates("snackBar", null)}
          severity={componentStates.snackBar.severity}
          message={componentStates.snackBar.message}
        />
      )}
      {drawers.openCreateCategories && (
        <ModifierGroupCreateEdit
          data-qaid='addon_create_categories_snackbar'
          open={drawers.openCreateCategories}
          onClose={() => toggleDrawer("openCreateCategories", false)}
          handleCreate={handleCreateModifier}
        />
      )}
      {drawers.openEditCategories && (
        <ModifierGroupCreateEdit
          data-qaid='addon_create_categories_snackbar'
          initialValues={modifierItem}
          open={drawers.openEditCategories}
          onClose={() => toggleDrawer("openEditCategories", false)}
          handleDelete={handleDeleteModifier}
          handleEdit={handleEditModifier}
        />
      )}
    </Grid>
  );
};

export default ModifiersGroupList;
