import { Button, Grid, IconButton } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputText,
  RightDrawer,
  SingleSelect,
  SnackBar,
  ColorSelector,
} from "../../../components";
import { CircleClose, Menu as MenuBookIcon } from "../../../components/SVG";
import MyContext from "../../../Provider/MyContext";
import { createCategory, taxProfileList } from "../../../redux/actions";
import styles from "../../../styles/index";
import { AvenirBlackH4, H4, H8 } from "../../../utils/text";
import { validateAlphaNumeric } from "../../../utils/util";

const CreateCategory = ({ open, catNames, callback, onClose }) => {
  const [cat_name, setCatName] = useState("");
  const taxProfilesList = useSelector(
    (state) => state.taxConfigurationReducer.taxProfileList
  );
  const [error, setError] = useState(null);
  const style = styles();
  const [selectedTaxProfile, setSelectedTaxProfile] = useState(null);
  const [colorSelection, setColorSelection] = useState({
    bg_colour: "#FFFFFF",
    font_colour: "#000000",
  });
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    dispatch(taxProfileList(restId.rest_id));
  }, []);

  const handleSelectTaxProfile = (value) => {
    setSelectedTaxProfile(value);
    setSnackbar(null);
  };

  const save = () => {
    let errorFlag = 0;
    if (cat_name && cat_name !== "") {
      if (catNames.indexOf(cat_name.toLowerCase().trim()) !== -1) {
        setError(`${cat_name} already exists`);
        errorFlag += 1;
      }
    } else {
      errorFlag += 1;
      setError(Context.langData.valid_name_error);
    }
    if (!selectedTaxProfile) {
      setSnackbar({
        message: Context.langData.no_tax_profile_error,
        severity: "error",
      });
      errorFlag += 1;
    }
    if (errorFlag === 0) {
      setSnackbar(null);
      dispatch(
        createCategory(
          {
            category_name: cat_name.trim(),
            taxp_id: selectedTaxProfile,
            ...colorSelection,
          },
          callback
        )
      );
    }
  };

  const onChange = (val) => {
    setCatName(validateAlphaNumeric(val));
    if (catNames.indexOf(val.toLowerCase()) === -1) {
      setError("");
    } else {
      setError(`${validateAlphaNumeric(val)} already exist`);
    }
  };

  const handleColorChange = (val) => {
    setColorSelection(val);
  };
  return (
    <RightDrawer data-qaid='createcategory_centermodal' open={open} padding={1}>
      <Grid
        style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <Grid
          style={{
            display: "flex",
            height: "85%",
            flexDirection: "column",
            overflowX: "auto",
          }}>
          <Grid
            data-qaid='createcategory_close_grid'
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}>
            <IconButton
              onClick={onClose}
              style={{ marginRight: 40, marginTop: 20 }}>
              <CircleClose data-qaid='createcategory_closeicon' size={20} />
            </IconButton>
          </Grid>
          <Grid
            data-qaid='createcategory_settings_grid'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 56,
              marginBottom: 10,
            }}>
            <MenuBookIcon
              data-qaid='createcategory_setting_icon'
              style={{ marginRight: 10 }}
              width={22.5}
              height={24}
              color='#707272'
            />
            <H4
              data-qaid='createcategory_settings'
              label={Context.langData.menu}
              style={{ color: "#707272" }}
            />
          </Grid>
          <H8
            data-qaid='createcategory_title'
            style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }}
            label={Context.langData.create_category}
          />

          <Grid
            data-qaid='createcategory_comment_grid'
            style={{
              marginLeft: 56,
              marginRight: 56,
              width: 500,
              marginBottom: 52,
            }}>
            <ColorSelector
              style={{ marginTop: 20, marginBottom: 20 }}
              onSelector={handleColorChange}
              name={cat_name}
            />

            <Grid
              data-qaid='createcategory_catname_grid'
              container
              style={{ display: "flex", flexDirection: "column" }}>
              <AvenirBlackH4
                data-qaid='createcategory_valuelabel'
                label={Context.langData.cat_name}
                style={{ marginBottom: 10 }}
              />
              <InputText
                data-qaid='createcategory_catname_input'
                value={cat_name}
                error={error}
                multiline
                rows={1}
                maxLength={45}
                onChange={(val) => onChange(val.target.value)}
                id='cat_name'
              />
            </Grid>
            <Grid
              data-qaid='createCategeory_taxProfilelistGrid'
              style={{ width: "100%", marginTop: 10 }}>
              <AvenirBlackH4
                data-qaid='createCategeory_taxProfileLabel'
                label={Context.langData.taxProfile_select_profile}
                style={{ marginTop: 20, paddingBottom: 10 }}
              />
              <SingleSelect
                list={taxProfilesList}
                value={selectedTaxProfile}
                valueKey='id'
                displayKey='name'
                placeholder='Select Tax Profile'
                handleChange={(e) => {
                  handleSelectTaxProfile(e.target.value);
                }}
                border={false}
                height={54}
                FormWidth='100%'
                borderStyle='1px solid #707272'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{
            display: "flex",
            height: "15%",
            flexDirection: "column",
            justifyContent: "flex-end",
            zIndex: 999,
          }}>
          <Grid
            data-qaid='createcategory_action_grid'
            container
            style={{ backgroundColor: "#FAFAFA" }}>
            <Grid
              data-qaid='createcategory_cancel_btn'
              item
              xs={12}
              lg={12}
              sm={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 32,
                marginBottom: 32,
              }}>
              <Button
                className={style.buttonClass}
                data-qaid='createcategory_cancel_button'
                onClick={onClose}
                variant='contained'
                style={{
                  backgroundColor: "#FFF",
                  marginRight: 16,
                  height: 40,
                  width: 103,
                  border: "2px solid #051D33",
                }}>
                <AvenirBlackH4
                  data-qaid='createcategory_cancel_text'
                  label={Context.langData.cancel}
                />
              </Button>
              <Button
                className={style.buttonClass}
                data-qaid='createcategory_save_btn'
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  marginRight: 32,
                  height: 40,
                  width: 90,
                }}
                onClick={save}>
                <AvenirBlackH4
                  data-qaid='createcategory_save_text'
                  label={Context.langData.save}
                />
              </Button>
            </Grid>
          </Grid>
          {snackbar && (
            <SnackBar
              data-qaid='createcategory_save_snackbar'
              open
              setOpen={() => setSnackbar(null)}
              severity={snackbar.severity}
              message={snackbar.message}
            />
          )}
        </Grid>
      </Grid>
    </RightDrawer>
  );
};

export default CreateCategory;
